.hero {
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
  min-height: 600px;
}

.hero::before {
  content: "";
  background: url("../../assets/img/hero-img.png") no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  height: 600px;
  width: 681px;
  box-shadow: -5px 5px 20px -5px #eee;
  z-index: -1;
}

@media screen and (max-width: 991px) {
  .hero {
    min-height: auto;
  }
  .hero::before {
    display: none;
  }
}

.hero .wrapContent > h1 {
  font-family: var(--main-font);
  font-size: 50px;
  color: var(--main-color);
  margin: 0 0 20px 0;
  line-height: 68px;
  font-weight: 800;
  letter-spacing: -2px;
}

@media screen and (max-width: 768px) {
  .hero .wrapContent > h1 {
    font-size: 38px;
    line-height: 58px;
  }
}

.hero .wrapContent > h1 span {
  position: relative;
}

.hero .wrapContent > h1 span svg {
  position: absolute;
  bottom: -16px;
  left: 0;
  width: 100%;
  z-index: -1;
  transform: rotate(2deg);
}

.hero .wrapContent > h2 {
  font-family: var(--sub-font);
  font-size: 18px;
  color: var(--text-color);
  margin: 0 0 30px 0;
  line-height: 29px;
  font-weight: 400;
}

@media screen and (min-width: 992px) {
  .hero .wrapContent > h2 {
    max-width: 40%;
  }
}

.btn {
  font-size: 16px;
  font-family: var(--main-font);
  margin-bottom: 40px;
}

.block h1 {
  font-weight: 700;
  font-size: 36px;
  color: var(--purle-color);
  margin: 0 0 5px 0;
}

.block h2 {
  font-weight: 600;
  font-family: var(--main-font);
  font-size: 16px;
  color: var(--main-color);
  margin: 0;
}
