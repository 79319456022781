.demos {
  background-color: #f8f9fa;
  position: relative;
}

.headings {
  text-align: center;
  position: relative;
  z-index: 1;
}

.headings h1 {
  font-size: 90px;
  font-weight: 700;
  margin: 0;
}

.headings h2 {
  font-family: var(--main-font);
  font-size: 40px;
  letter-spacing: -2px;
  font-weight: 800;
  color: var(--main-color);
  margin: 20px 0;
}

.headings h3 {
  font-family: var(--sub-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
  margin: 20px 0;
}

.demosItems {
  position: relative;
  z-index: 1;
  margin: 60px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.demos li {
  text-align: center;
  background-color: white;
  box-shadow: 0 0 15px -4px #e5e7e9;
  width: calc(100% / 3 - 20px);
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 991px) {
  .demos li {
    width: calc(100% / 2 - 15px);
  }
  .demos li a img {
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .demos li {
    width: 100%;
  }
}

.demos li:hover {
  box-shadow: 0 10px 15px -4px #e5e7e9;
  transform: translateY(-5px);
}

.thumbWrap {
  background-color: #dde1e5;
  padding-top: 7%;
  display: flex;
  justify-content: center;
}

.demos li a img {
  max-width: 85%;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 15px -4px #bdc2c6;
}

.demos li a h4 {
  color: var(--main-color);
  margin: 20px 0;
  font-family: var(--main-font);
  font-weight: 600;
  font-size: 17px;
}

.whmcsDemo {
  position: relative;
  margin-top: 30px;
  background-color: white;
  box-shadow: 0 0 15px -4px #e5e7e9;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.whmcsDemo:hover {
  box-shadow: 0 10px 15px -4px #e5e7e9;
  transform: translateY(-5px);
}

.imgWrap {
  width: 50%;
  position: relative;
  z-index: 2;
  display: flex;
}

.imgWrap img {
  box-shadow: -10px 0 30px -6px #eee;
  margin-left: auto;
}

.info {
  padding: 30px 50px;
  z-index: 2;
  font-family: var(--main-font);
}

.info h1 {
  font-size: 40px;
  margin: 30px 0;
  color: var(--main-color);
  line-height: 60px;
  font-weight: 800;
  letter-spacing: -2px;
}

.info a {
  font-size: 16px;
}

.navBgCustom {
  z-index: 1 !important;
}

.blackDivider {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

@media screen and (max-width: 991px) {
  .whmcsDemo {
    flex-flow: column;
  }
  .info {
    text-align: center;
  }
  .info h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .imgWrap {
    width: 100%;
  }
  .imgWrap img {
    object-fit: contain !important;
    width: 100%;
    height: auto;
  }
}
