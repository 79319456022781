.nav {
  padding: 20px 0;
  font-family: var(--main-font);
  font-weight: 600;
  z-index: 99;
  transition: 0.5s height cubic-bezier(0.73, 0.32, 0.34, 1.5),
    0.5s padding cubic-bezier(0.73, 0.32, 0.34, 1.5),
    0.5s margin cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.5s 0.2s color,
    0.2s background-color;
}

.sticky {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
}

.nav ul {
  margin: 0 30px 0 auto;
  padding: 0;
  list-style: none;
  gap: 0 30px;
}

.nav ul li a {
  color: var(--main-color);
  font-size: 15px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  padding-bottom: 6px;
}

.nav ul li a:hover {
  border-color: var(--purle-color);
}

.mobileTrigger {
  display: none;
}

@media screen and (max-width: 991px) {
  .mobileTrigger {
    --bs-gutter-x: 1.5rem;
    display: inline-flex;
    position: absolute;
    top: 30px;
    right: calc(var(--bs-gutter-x) * 0.5);
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
  }
  .navBg {
    top: -50px;
  }
  .mainWrapper {
    flex-flow: column;
    align-items: flex-start;
    gap: 20px 0;
  }
  .nav ul {
    display: none;
    flex-flow: column;
    margin: 0;
    gap: 20px 0;
    align-items: flex-start;
  }
  .nav ul.active {
    display: flex;
  }
  .btn {
    display: none;
  }
  .btn.active {
    display: flex;
  }
}
