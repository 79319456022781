.testimonials {
  background-color: #f8f9fa;
  padding: 50px 0 100px;
}

.headings {
  font-family: var(--main-font);
  position: sticky;
  top: 120px;
}

.headings h1 {
  font-size: 14px;
  background-color: #e9ecef;
  color: var(--main-color);
  border-radius: 4px;
  margin: 0;
  padding: 6px 15px;
  display: inline-block;
  font-weight: 600;
}

.headings h2 {
  color: var(--main-color);
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  margin: 20px 0;
  letter-spacing: -2px;
}

.headings h3 {
  font-family: var(--sub-font);
  color: var(--text-color);
  font-weight: 400;
  margin: 20px 0 8px;
  font-size: 15px;
}

.rate {
  display: flex;
  gap: 5px;
}

.testimonialsList {
  max-width: 60%;
}

.testimonialsList .block {
  float: right;
  margin-left: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 30px;
  width: calc(50% - 30px);
  border: 2px solid #cfd5db;
  text-align: center;
}

.testimonialsList .block img {
  box-shadow: 0 0 20px #e8ebef;
  border-radius: 50%;
  height: 70px;
}

.testimonialsList .block p {
  margin: 20px 0;
  color: var(--text-color);
  font-size: 14px;
  font-family: var(--sub-font);
  line-height: 24px;
}

.rating {
  background-color: #fff6e8;
  display: inline-flex;
  padding: 8px 15px;
  border-radius: 2px;
  gap: 5px;
  width: auto;
  margin: 0 auto;
}

.testimonialsList .block h4 {
  color: var(--main-color);
  font-family: var(--main-font);
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 5px;
}

.testimonialsList .block h5 {
  color: #567c1e;
  font-family: var(--sub-font);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .wrapElements {
    flex-flow: column !important;
  }
  .headings {
    width: 100%;
    position: static;
    text-align: center;
  }
  .rate {
    margin: 0 auto;
    display: inline-flex;
  }
  .testimonialsList {
    margin-top: 30px;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  .block {
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
  }
}
