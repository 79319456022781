.features {
  background-color: #2c2c39;
  padding: 100px 0 0;
  text-align: center;
  color: white;
}

.features > h1 {
  line-height: 60px;
  font-family: var(--main-font);
  font-size: 40px;
  margin: 0 0 10px 0;
  font-weight: 800;
  letter-spacing: -2px;
}

.features > h1 span {
  position: relative;
}

.features > h1 span svg {
  position: absolute;
  bottom: -21px;
  left: 0;
  width: 100%;
}

.featuresWrap {
  gap: 30px;
  margin-top: 50px;
}

.block {
  background-color: #353543;
  border-radius: 6px;
  overflow: hidden;
  width: 50%;
}

@media screen and (max-width: 767px) {
  .featuresWrap {
    flex-wrap: wrap;
  }
  .block {
    width: 100%;
  }
}

.content {
  padding: 40px 90px;
  min-height: 288px;
}

.content h2 {
  display: inline-block;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--main-font);
  border-radius: 35px;
  padding: 6px 10px;
  color: rgba(0, 0, 0, 0.8);
}

.content h3 {
  margin: 25px;
  font-weight: 800;
  font-family: var(--main-font);
  font-size: 30px;
  letter-spacing: -2px;
}

.content p {
  margin: 0 0 10px 0;
  color: #c3c3c9;
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  font-family: var(--sub-font);
}

.featuresWrap img {
  display: block;
  object-fit: contain;
  max-width: 100%;
  margin: 0 auto 40px;
}
