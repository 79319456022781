.packs {
  margin: 100px 0;
  text-align: center;
}

.packs h1 {
  font-family: var(--main-font);
  font-size: 40px;
  color: var(--main-color);
  margin: 0 0 15px 0;
  font-weight: 800;
  letter-spacing: -2px;
}

.packs h2 {
  font-family: var(--sub-font);
  font-size: 17px;
  color: var(--text-color);
  margin: 0 0 60px 0;
  font-weight: 400;
}

.featuresList {
  width: 60%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px 0;
}

.item {
  width: calc(100% / 4 - 20px);
}

@media screen and (max-width: 991px) {
  .featuresList {
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .item {
    width: calc(100% / 2 - 20px);
  }
}
