html {
  scroll-behavior: smooth;
}

:root {
  --main-font: "Montserrat", sans-serif;
  --sub-font: "Poppins", sans-serif;
  --main-color: #343a40;
  --purle-color: #878dec;
  --text-color: #646b70;
}

body {
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

a {
  text-decoration: none;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

.primary-btn {
  display: inline-block;
  background: linear-gradient(to right, #7d8dff, #ff4f81);
  font-weight: 600;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0rem solid transparent;
  padding: 0.5625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.8;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
  line-break: normal;
}

.primary-btn:hover {
  opacity: 0.7;
}

.gap-60 {
  gap: 60px;
}

.text-gradient {
  background: linear-gradient(to right, #7d8dff, #ff4f81);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
