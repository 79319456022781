.footerDivider {
  display: flex;
  transform: scaleX(-1) scaleY(-1);
  position: relative;
  top: -1px;
}

.footer {
  text-align: center;
  background-color: #2c2c39;
  color: #fff;
}

.wrapElements {
  margin-top: -50px;
  position: relative;
}

.logoImg {
  display: inline-block;
}

.wrapElements h1 {
  margin: 30px 0;
  font-size: 40px;
  font-weight: 800;
  line-height: 55px;
}

.wrapElements h1 span {
  position: relative;
}

.wrapElements h1 span svg {
  position: absolute;
  bottom: -23px;
  left: 0;
  width: 100%;
  z-index: 0;
}

.wrapElements p {
  color: #e0e0e7;
  margin: 0;
  font-size: 15px;
  line-height: 26px;
  font-family: var(--sub-font);
}

.wrapElements .btn {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--main-font);
  margin: 30px 0;
}

.footer hr {
  border-color: #414150;
  opacity: 0.2;
  margin: 0;
}

.footer h2 {
  margin: 15px 0;
  font-family: var(--sub-font);
  color: #fff;
  display: inline-block;
  font-size: 14px;
}

.footer h2 a {
  color: inherit;
}

@media screen and (max-width: 767px) {
  .footer h1 {
    font-size: 30px;
    line-height: 45px;
  }
  .wrapElements {
    margin-top: -20px;
  }
}
